/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-13",
    versionChannel: "nightly",
    buildDate: "2024-04-13T00:05:31.557Z",
    commitHash: "e687d16ecb1d4e3fc1f4d3ea06183f37a29eb835",
};
